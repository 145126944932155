import React from 'react';

const SearchPopup = () => (
  <div className="search-popup">
    <div className="search-popup__overlay search-toggler" />
    <div className="search-popup__content">
      <form action="#">
        <label htmlFor="search" className="sr-only">
          search here
        </label>
        <input type="text" id="search" placeholder="Search Here..." />
        <button type="submit" aria-label="search submit" className="thm-btn">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  </div>
);

export default SearchPopup;
