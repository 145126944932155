import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import NavLinks from './nav-links';

import logoDark from '../../assets/images/gamily-logo.svg';

const HeaderTwo = () => (
  <div className="main-header__two">
    <div className="main-header__top">
      <Container>
        <p>SUPPORT FOR FAMILIES OF GAMBLING ADDICTS</p>
        <div className="main-header__social">
          <a
            href="https://twitter.com/GAMILY_AO"
            aria-label="twitter"
            target="_blank"
          >
            <i className="fab fa-twitter" />
          </a>
          <a
            href="https://www.facebook.com/groups/gamilyaffectedothers/?ref=share"
            aria-label="facebook"
            target="_blank"
          >
            <i className="fab fa-facebook-square" />
          </a>
        </div>
      </Container>
    </div>
    <div className="header-upper">
      <Container>
        <div className="logo-box">
          <Link to="/">
            <img src={logoDark} width="180" alt="" />
          </Link>
          <span className="fa fa-bars mobile-nav__toggler" />
        </div>

        <div className="header-info">
          <div className="header-info__box">
            <i className="azino-icon-email1" />
            <div className="header-info__box-content">
              <h3>Email</h3>
              <p>
                <a href="mailto:info@gamily.co.uk">
                  info@gamily.co.uk
                </a>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <nav className="main-menu">
      <Container>
        <NavLinks extraClassName="dynamic-radius" />
      </Container>
    </nav>
  </div>
);

export default HeaderTwo;
