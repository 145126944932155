import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import logoLight from '../assets/images/logo-light.png';
import blogPost1 from '../assets/images/resources/footer-img-1-1.jpg';
import blogPost2 from '../assets/images/resources/footer-img-1-2.jpg';
import Logo from '../assets/images/footer-logo.png';

const Footer = () => (
  <section className="site-footer">
    <div className="main-footer pt-142 pb-80">
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12}>
            <div className="footer-widget mt-30 mb-30 footer-widget__about">
              <Link to="/">
                <img src={Logo} className="footer-widget__logo" alt="" />
              </Link>
            </div>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <div className="footer-widget footer-widget__link mb-40">
              <h3 className="footer-widget__title">Explore</h3>
              <ul className="list-unstyled footer-widget__link-list">
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/forum">Forum</Link>
                </li>
                <li>
                  <Link to="/how-to">How to</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col lg={3} md={6} sm={12}>
            <div className="footer-widget mb-40 footer-widget__blog">
              <h3 className="footer-widget__title">Blog</h3>
              <ul className="list-unstyled footer-widget__blog">
                <li>
                  <img src={blogPost1} alt="" />
                  <p>22 May, 2020</p>
                  <h3>
                    <Link to="/news-details">
                      You can help the poor in need
                    </Link>
                  </h3>
                </li>
                <li>
                  <img src={blogPost2} alt="" />
                  <p>22 May, 2020</p>
                  <h3>
                    <Link to="/news-details">Rise fund for Healthy Food</Link>
                  </h3>
                </li>
              </ul>
            </div>
          </Col> */}
          <Col lg={4} md={6} sm={12}>
            <div className="footer-widget mb-40 footer-widget__newsletter">
              <h3 className="footer-widget__title">Forum</h3>
              <p>
                The GAMILY forum is a safe and secure space, where you as an
                affected other can ask questions, share your story and offer advice.
                Most importantly, you can receive support, help and understanding
                from those with lived experiences of a loved ones gambling
                addiction.
              </p>
              <div className="footer-widget__newsletter-btn-wrap">
                <Link to="/register" className="thm-btn dynamic-radius">
                  Join Now
                </Link>
              </div>
              <div className="mc-form__response" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <p>© Copyright {new Date().getFullYear()} Gamily</p>
      </div>
    </div>
  </section>
);

export default Footer;
