import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import NavLinks from './header/nav-links';

import logoDark from '../assets/images/gamily-logo.svg';

const MobileNav = () => {
  const [hasMounted, setHasMounted] = useState(false);
  const mobileMenu = () => {
    const mobileNavToggler = document.querySelectorAll('.mobile-nav__toggler');
    if (mobileNavToggler) {
      mobileNavToggler.forEach((mobileNavTogglerBtn) => {
        mobileNavTogglerBtn.addEventListener('click', function (e) {
          console.log('clicked');
          document
            .querySelector('.mobile-nav__wrapper')
            .classList.toggle('expanded');
          e.preventDefault();
        });
      });
    }
    // search toggler
    const searchCloser = document.querySelectorAll('.search-toggler');
    if (searchCloser) {
      searchCloser.forEach((searchCloserBtn) => {
        searchCloserBtn.addEventListener('click', function (e) {
          document.querySelector('.search-popup').classList.toggle('active');
          e.preventDefault();
        });
      });
    }

    // Close Mobile Menu
    const sideMenuCloser = document.querySelectorAll('.side-menu__toggler');
    if (sideMenuCloser) {
      sideMenuCloser.forEach((sideMenuCloserBtn) => {
        sideMenuCloserBtn.addEventListener('click', function (e) {
          document
            .querySelector('.mobile-nav__wrapper')
            .classList.remove('expanded');
          e.preventDefault();
        });
      });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      mobileMenu();
      setHasMounted(true);
      return () => {
        mobileMenu();
      };
    }
  }, [hasMounted]);

  if (!hasMounted) {
    return null;
  }

  return (
    <div className="mobile-nav__wrapper">
      <div className="mobile-nav__overlay side-menu__toggler side-menu__block-overlay" />
      <div className="mobile-nav__content">
        <span className="mobile-nav__close side-menu__toggler  side-menu__close-btn">
          <i className="far fa-times" />
        </span>

        <div className="logo-box">
          <Link to="/">
            <img src={logoDark} width="101" alt="" />
          </Link>
        </div>
        <div className="mobile-nav__container">
          <NavLinks />
        </div>

        <ul className="mobile-nav__contact list-unstyled">
          <li>
            <i className="azino-icon-email" />
            <a href="mailto:info@gamily.co.uk">
              info@gamily.co.uk
            </a>
          </li>
        </ul>
        <div className="mobile-nav__top">
          <div className="mobile-nav__social">
            <a href="https://twitter.com/GAMILY_AO" aria-label="twitter">
              <i className="fab fa-twitter" />
            </a>
            <a
              href="https://www.facebook.com/groups/gamilyaffectedothers/?ref=share"
              aria-label="facebook"
            >
              <i className="fab fa-facebook-square" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
