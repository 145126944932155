import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const NavLinks = ({ extraClassName }) => {
  const data = useStaticQuery(graphql`
    query BlogToggle {
      storyblokEntry(slug: { eq: "blog" }) {
        field_blog_enabled_boolean
      }
    }
  `);
  const BLOG_ACTIVE = data.storyblokEntry.field_blog_enabled_boolean;

  const handleDropdownStatus = (e) => {
    const clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector('.dropdown-list').classList.toggle('show');
  };
  return (
    <ul className={`main-menu__list ${extraClassName}`} id="menu">
      <li className="menu__home">
        <Link to="/">Home</Link>
      </li>
      <li className="menu__how-to">
        <Link to="/how-to">How to</Link>
      </li>
      <li className="dropdown menu__support">
        <a href="#menu">Help &amp; Support</a>
        <button
          type="button"
          aria-label="dropdown toggler"
          onClick={handleDropdownStatus}
        >
          <i className="fa fa-angle-down" />
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/support-affected-others">Affected Others</Link>
          </li>
          <li>
            <Link to="/support-loved-ones">Loved Ones</Link>
          </li>
        </ul>
      </li>
      {BLOG_ACTIVE && (
        <li className="menu__blog">
          <Link to="/blog">Blog</Link>
        </li>
      )}
      <li className="menu__forum">
        <Link to="/forum">Forum</Link>
      </li>
      <li className="menu__contact">
        <Link to="/contact">Contact</Link>
      </li>

      <li className="search-btn">
        <ul className="search-btn-nested">
          <li>
            <Link to="/login">Login</Link>
          </li>
          <li>
            <Link to="/register">Register</Link>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default NavLinks;
